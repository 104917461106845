export type BlogPosting = {
  id: string;
  date: Date;
  title: string;
  subtitle: string;
  content: string[];
};

export const Content: BlogPosting[] = [
  // {
  //   id: "1",
  //   date: new Date(),
  //   title: "Webinar Recap: How to Overcome Nurse Turnover with Flexibility",
  //   subtitle:
  //     "Healthcare facilities can evolve to overcome the nurse staffing turnover crisis. Here's how CareRev can help.",
  //   content: [
  //     "Nurses were under stress well before the pandemic began. They knew their worth to their patients and to the healthcare community at large, but struggled to feel appreciated amid growing levels of exhaustion and burnout.",
  //     "That struggle has continued, but so has their understanding of their worth. Their needs and a newfound sense of empowerment are shifting the narrative around healthcare nurse staffing. The shift also stems from nearly 195,000 registered nurse openings anticipated each year on average for the next 10 years and retirements that are outpacing new nurses entering the field. Efficient workforce strategies are crucial to solving the nursing shortage and averting strain on the healthcare system.",
  //     "What workforce strategies should healthcare facility leaders have in place? Technology-enabled nurse staffing solutions that prioritize the needs of modern nurses are key. With these, leaders can gain access to expert nursing professionals to circumvent high turnover and increase retention rates. They can also deliver what today’s nurses want within the profession, their work cultures, and the healthcare community.",
  //     "In a recent webinar, CareRev CEO and founder Will Patterson chatted with Teri Lux, President and COO at Froedtert Menomonee Falls, on the urgent need to address the needs of nurses and how technology-enabled solutions have evolved healthcare staffing.",
  //   ],
  // },
  // {
  //   id: "2",
  //   date: new Date(),
  //   title: "Test title",
  //   subtitle: "Subtitle",
  //   content: ["Paragraph 1", "Paragraph 2"],
  // },
  // {
  //   id: "3",
  //   date: new Date(),
  //   title: "Test title",
  //   subtitle: "Subtitle",
  //   content: ["Paragraph 1", "Paragraph 2"],
  // },
  // {
  //   id: "4",
  //   date: new Date(),
  //   title: "Test title",
  //   subtitle: "Subtitle",
  //   content: ["Paragraph 1", "Paragraph 2"],
  // },
];
