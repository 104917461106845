import "../styles/styles.css";
import { useEffect, useState } from "react";
import { Drawer } from "@mui/material";
import { ButtonUnstyled } from "@mui/base";
import { MdClose } from "react-icons/md";
import { FaBars, FaChevronDown, FaStethoscope } from "react-icons/fa";

import Colors from "../styles/Colors";
import { useNavigate } from "react-router-dom";

export default function Header() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  const [hoverMenu, setHoverMenu] = useState<
    "providers" | "facilities" | undefined
  >(undefined);

  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  });

  const updateWidthAndHeight = () => {
    setWindowWidth(window.innerWidth);
  };

  const mobileTrigger = 800;

  return (
    <div
      style={{
        top: 0,
        left: 0,
        right: 0,
        margin: 0,
        background: Colors.border,
        padding: 20,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        zIndex: 20,
        borderBottom: `1px solid lightgray`,
      }}
    >
      <ButtonUnstyled
        onClick={() => {
          navigate("/");
        }}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          fontFamily: "Urbanist",
          padding: 4,
          background: "none",
          border: "none",
          cursor: "pointer",
          borderRadius: 100,
        }}
      >
        <div
          style={{
            background: Colors.vDarkBlue,
            borderRadius: 24,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            height: 48,
            width: 48,
          }}
        >
          <FaStethoscope style={{ color: "#FBFBFD" }} size={20} />
        </div>
        <h2
          style={{
            color: Colors.vDarkBlue,
            fontSize: 40,
            fontWeight: 800,
          }}
        >
          Pagerr
        </h2>
      </ButtonUnstyled>
      {windowWidth > mobileTrigger ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: 20,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 12,
            }}
          >
            <div
              onMouseEnter={() => setHoverMenu("facilities")}
              onMouseLeave={() => setHoverMenu(undefined)}
            >
              <ButtonUnstyled
                style={{
                  border: "none",
                  borderRadius: 4,
                  paddingBlock: 16,
                  paddingInline: 8,
                  cursor: "pointer",
                  color: Colors.vGray,
                  fontSize: 16,
                  fontWeight: 700,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 20,
                }}
              >
                Facilities
                <FaChevronDown size={16} />
              </ButtonUnstyled>
              {hoverMenu === "facilities" ? (
                <div
                  style={{
                    position: "absolute",
                    background: "#fff",
                    boxShadow: "2px 2px 2px rgba(0,0,0,0.1)",
                    borderRadius: 8,
                    contain: "paint",
                    flex: 1,
                    minWidth: 120,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <ButtonUnstyled
                    className="menuLink"
                    onClick={() => window.open("/signup/facility")}
                  >
                    Signup
                  </ButtonUnstyled>
                  <ButtonUnstyled
                    className="menuLink"
                    onClick={() => window.open("https://dashboard.pagerr.app")}
                  >
                    Login
                  </ButtonUnstyled>
                </div>
              ) : null}
            </div>

            <div
              onMouseEnter={() => setHoverMenu("providers")}
              onMouseLeave={() => setHoverMenu(undefined)}
            >
              <ButtonUnstyled
                style={{
                  border: "none",
                  borderRadius: 4,
                  paddingBlock: 16,
                  paddingInline: 8,
                  cursor: "pointer",
                  color: Colors.vGray,
                  fontSize: 16,
                  fontWeight: 700,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 20,
                }}
              >
                Providers
                <FaChevronDown size={16} />
              </ButtonUnstyled>
              {hoverMenu === "providers" ? (
                <div
                  style={{
                    position: "absolute",
                    background: "#fff",
                    boxShadow: "2px 2px 2px rgba(0,0,0,0.1)",
                    borderRadius: 8,
                    contain: "paint",
                    flex: 1,
                    minWidth: 120,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <ButtonUnstyled
                    className="menuLink"
                    onClick={() => window.open("/signup/provider")}
                  >
                    Signup
                  </ButtonUnstyled>
                  <ButtonUnstyled
                    className="menuLink"
                    onClick={() => window.open("https://dashboard.pagerr.app")}
                  >
                    Login
                  </ButtonUnstyled>
                </div>
              ) : null}
            </div>
            <ButtonUnstyled
              style={{
                border: "none",
                borderRadius: 4,
                paddingBlock: 16,
                paddingInline: 20,
                cursor: "pointer",
                color: Colors.vGray,
                fontSize: 16,
                fontWeight: 700,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 20,
              }}
              onClick={() => navigate("/blog")}
            >
              Blog
              {/* <FaChevronDown size={16} /> */}
            </ButtonUnstyled>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 12,
            }}
          >
            <ButtonUnstyled
              className="pressable"
              onClick={() => window.open("/signup")}
              style={{
                background: Colors.bluePrimary,
                border: "none",
                borderRadius: 4,
                paddingInline: 8,
                paddingBlock: 16,
                cursor: "pointer",
                fontWeight: 700,
              }}
            >
              <p style={{ color: Colors.vWhite, whiteSpace: "nowrap" }}>
                Signup
              </p>
            </ButtonUnstyled>
            <ButtonUnstyled
              className="pressable"
              onClick={() => window.open("https://dashboard.pagerr.app")}
              style={{
                background: Colors.vBlack,
                color: Colors.vWhite,
                border: "none",
                borderRadius: 4,
                padding: 8,
                paddingBlock: 16,
                cursor: "pointer",
                fontWeight: 700,
              }}
            >
              <p style={{ color: Colors.vWhite, whiteSpace: "nowrap" }}>
                Login
              </p>
            </ButtonUnstyled>
          </div>
        </div>
      ) : null}
      {windowWidth < mobileTrigger ? (
        <>
          <FaBars
            tabIndex={1}
            size={24}
            style={{
              color: Colors.bluePrimary,
              padding: 12,
              borderRadius: 12,
            }}
            cursor="pointer"
            onClick={() => setMenuOpen(true)}
          />
          <Drawer
            anchor="top"
            open={menuOpen}
            onClose={() => setMenuOpen(false)}
          >
            <div
              style={{
                maxWidth: windowWidth,
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                paddingBottom: 16,
                gap: 20,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: 8,
                }}
              >
                <ButtonUnstyled
                  onClick={() => {
                    navigate("/");
                    setMenuOpen(false);
                  }}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    fontFamily: "Urbanist",
                    padding: 0,
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  <div
                    style={{
                      background: Colors.vDarkBlue,
                      borderRadius: 20,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      height: 30,
                      width: 30,
                    }}
                  >
                    <FaStethoscope style={{ color: "#FBFBFD" }} size={14} />
                  </div>
                  <h2
                    style={{
                      color: Colors.vDarkBlue,
                      fontSize: 28,
                      fontWeight: 600,
                    }}
                  >
                    Pagerr
                  </h2>
                </ButtonUnstyled>
                <MdClose
                  size={28}
                  style={{
                    color: Colors.vGray,
                    cursor: "pointer",
                  }}
                  onClick={() => setMenuOpen(false)}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  paddingInline: 20,
                  flexDirection: "column",
                  gap: 12,
                }}
              >
                <ButtonUnstyled
                  className="pressable"
                  onClick={() => {
                    window.open("/signup");
                    setMenuOpen(false);
                  }}
                  style={{
                    background: Colors.bluePrimary,
                    border: "none",
                    borderRadius: 4,
                    paddingBlock: 16,
                    paddingInline: 40,
                    cursor: "pointer",
                    // marginInline: 12,
                    // boxShadow: "2px 2px 4px rgba(0,0,0,0.2)",
                    color: Colors.vWhite,
                    fontSize: 16,
                    fontWeight: 700,
                  }}
                >
                  <p style={{ color: Colors.vWhite, whiteSpace: "nowrap" }}>
                    Signup
                  </p>
                </ButtonUnstyled>

                <ButtonUnstyled
                  className="pressable"
                  onClick={() => {
                    window.open("https://dashboard.pagerr.app");
                    setMenuOpen(false);
                  }}
                  style={{
                    background: Colors.vBlack,
                    border: "none",
                    borderRadius: 4,
                    paddingBlock: 16,
                    paddingInline: 40,
                    cursor: "pointer",
                    // marginInline: 12,
                    // boxShadow: "2px 2px 4px rgba(0,0,0,0.2)",
                    color: Colors.vWhite,
                    fontSize: 16,
                    fontWeight: 700,
                  }}
                >
                  Login
                </ButtonUnstyled>
              </div>
            </div>
          </Drawer>
        </>
      ) : null}
    </div>
  );
}
