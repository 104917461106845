import "../styles/styles.css";
import Colors from "../styles/Colors";
import { Link } from "@mui/material";
import { FaStethoscope } from "react-icons/fa";

export default function Footer() {
  return (
    <div
      style={{
        background: Colors.vBlack,
        padding: 20,
        zIndex: 20,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <div
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        >
          <LinkContainer>
            <FooterLinkHeader label={"Company"} />
            <FooterLink label={"About"} link="/" />
            <FooterLink label={"FAQs"} link="/faq" />
            <FooterLink
              label={"Contact"}
              link="mailto:hello@pagerr.app?subject=Pagerr Introduction"
            />
            <FooterLink label={"Privacy Policy"} />
            <FooterLink label={"Terms & Conditions"} />
          </LinkContainer>
          <LinkContainer>
            <FooterLinkHeader label={"For Providers"} />
            {/* <FooterLink label={"Provider Information"} link="/providers" /> */}
            <FooterLink label={"Signup"} link="/signup/provider" />
            <FooterLink label={"Login"} link="https://dashboard.pagerr.app" />
          </LinkContainer>
          <LinkContainer>
            <FooterLinkHeader label={"For Facilities"} />
            {/* <FooterLink label={"Facility Information"} link="/facilities" /> */}
            <FooterLink label={"Signup"} link="/signup/facility" />
            <FooterLink label={"Login"} link="https://dashboard.pagerr.app" />
          </LinkContainer>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            flex: 1,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                background: "#ffffff",
                borderRadius: 20,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                height: 30,
                width: 30,
              }}
            >
              <FaStethoscope style={{ color: Colors.vBlack }} size={16} />
            </div>
            <h2
              style={{
                color: "#ffffff",
                fontSize: 28,
                fontWeight: 600,
              }}
            >
              Pagerr
            </h2>
          </div>

          {/* <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <a
              href="https://www.facebook.com/vaia.us"
              target="_blank"
              rel="noreferrer"
              style={{ color: "white", margin: 12, cursor: "pointer" }}
              className="pressable"
            >
              <FaFacebookSquare size={24} className="pressable" />
            </a>
            <a
              href="https://www.instagram.com/vaia.travel/"
              target="_blank"
              rel="noreferrer"
              style={{ color: "white", margin: 12, cursor: "pointer" }}
              className="pressable"
            >
              <FaInstagram size={24} />
            </a>
          </div> */}
        </div>
      </div>
      <div
        style={{
          margin: 20,
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <p
          style={{
            fontSize: 12,
            color: Colors.vWhite,
            margin: 10,
            textAlign: "left",
          }}
        >
          © All rights reserved to Pagerr Medical LLC.
        </p>
      </div>
    </div>
  );
}

function LinkContainer(props: { children?: any }) {
  return (
    <div
      style={{
        color: Colors.vWhite,
        textAlign: "left",
        marginInline: 20,
        gap: 10,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {props.children}
    </div>
  );
}

function FooterLinkHeader(props: { label: string }) {
  return (
    <p
      style={{
        color: Colors.vWhite,
        marginInline: 0,
        fontSize: 20,
        fontWeight: 800,
      }}
    >
      {props.label}
    </p>
  );
}

function FooterLink(props: { label: string; link?: string }) {
  return (
    <Link
      href={props.link}
      underline="hover"
      style={{
        display: "flex",
        cursor: "pointer",
        fontSize: 15,
        color: Colors.vWhite,
      }}
    >
      {props.label}
    </Link>
  );
}
