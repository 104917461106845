import { ButtonUnstyled } from "@mui/base";
import { Dialog } from "@mui/material";
import { useState } from "react";
import { FaCheckCircle, FaStethoscope } from "react-icons/fa";
import { Oval } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import Colors from "../styles/Colors";
import "../styles/login.css";

export default function FacilitySignup() {
  let navigate = useNavigate();

  const [email, setEmail] = useState<string>("");
  const [type, setType] = useState<undefined | string>(undefined);

  const [loading, setLoading] = useState<boolean>(false);

  const [dialog, setDialog] = useState<
    undefined | { title: string; message: string }
  >(undefined);

  function validateEmail(): string | boolean {
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      return "Please enter a valid email address";
    }

    return true;
  }

  function validateType(): boolean {
    if (type === undefined) {
      return false;
    }

    return true;
  }

  function validate() {
    if (validateEmail() === true && validateType() === true) {
      return true;
    }
    return false;
  }

  var axios = require("axios");
  var data = JSON.stringify({
    fields: {
      Email: email,
      Type: "facility",
      Subtype: type,
      Assignee: {
        id: "usrWQal2HMVso4Urs",
        email: "j.aliot011@gmail.com",
        name: "Joseph Alioto",
      },
      Status: "Todo",
    },
    typecast: true,
  });

  var config = {
    method: "post",
    url: "https://api.airtable.com/v0/appvC3ANqONYNSyO4/Signup",
    headers: {
      Authorization: "Bearer keybnv0gNR7elsEEQ",
      "Content-Type": "application/json",
    },
    data: data,
  };

  function Send() {
    axios(config)
      .then(function (response: any) {
        console.log(JSON.stringify(response.data));
        setDialog({
          title: "Success!",
          message:
            "Someone from the Pagerr team will be in contact shortly to get you all set up.",
        });
      })
      .catch(function (error: any) {
        console.log(error);
        setDialog({
          title: "Uh oh!",
          message: "Looks like something went wrong. Please try again.",
        });
      });
  }

  return (
    <div
      style={{
        minHeight: "100vh",
        background: Colors.border,
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Dialog
        open={!!dialog}
        onClose={() => {
          setDialog(undefined);
          setLoading(false);
        }}
        fullWidth
      >
        <div style={{ padding: 12, textAlign: "left" }}>
          <h3
            style={{
              fontWeight: 900,
              marginInline: 0,
              marginBottom: 10,
              color: Colors.bluePrimary,
            }}
          >
            {dialog?.title}
          </h3>
          <p style={{ margin: 0 }}>{dialog?.message}</p>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 32,
            }}
          >
            <ButtonUnstyled
              className="pressable"
              onClick={() => {
                setDialog(undefined);
                setLoading(false);
                navigate("/");
              }}
              style={{
                background: Colors.bluePrimary,
                color: Colors.vWhite,
                paddingInline: 40,
                border: "none",
                borderRadius: 4,
                padding: 8,
                cursor: "pointer",
                boxShadow: "2px 2px 4px rgba(0,0,0,0.2)",
                fontFamily: "Urbanist",
                fontWeight: 700,
                fontSize: 16,
              }}
            >
              OK
            </ButtonUnstyled>
          </div>
        </div>
      </Dialog>
      <div style={{ marginBottom: 400, marginTop: 40, marginInline: 20 }}>
        <span
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: 20,
          }}
        >
          <div
            style={{
              background: Colors.vDarkBlue,
              borderRadius: 24,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              height: 48,
              width: 48,
            }}
          >
            <FaStethoscope style={{ color: "#FBFBFD" }} size={20} />
          </div>
          <h2
            style={{
              color: Colors.vDarkBlue,
              fontSize: 40,
              fontWeight: 800,
            }}
          >
            Pagerr
          </h2>
        </span>
        <div
          style={{
            display: "flex",
            padding: 32,
            borderRadius: 8,
            background: "#fff",
            flexDirection: "column",
            textAlign: "left",
            boxShadow: "0px 2px 12px rgba(0,0,0,0.2)",
            gap: 20,
          }}
        >
          <h3
            style={{
              fontWeight: 900,
              fontSize: 20,
              color: Colors.bluePrimary,
              marginInline: 0,
              textAlign: "center",
            }}
          >
            Facility Account Creation
          </h3>
          <label>
            Email:
            <div
              style={{
                display: "flex",
                contain: "layout",
                alignItems: "center",
              }}
            >
              <input
                disabled={loading}
                type="email"
                onChange={(evt) => setEmail(evt.target.value)}
                value={email}
                placeholder="hello@pagerr.app"
                style={{ flex: 1 }}
              />
              {validateEmail() === true ? (
                <FaCheckCircle
                  size={20}
                  style={{
                    flex: 1,
                    color: Colors.vGreen,
                    position: "absolute",
                    right: 12,
                  }}
                />
              ) : null}
            </div>
          </label>
          <label>
            I am a...
            <fieldset
              disabled={loading}
              name="type"
              style={{
                padding: 0,
                border: "none",
                display: "flex",
                flexWrap: "wrap",
                gap: 12,
                flexDirection: "column",
                fontSize: 20,
              }}
            >
              <label
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  fontSize: "inherit",
                  fontWeight: 400,
                  gap: 8,
                }}
              >
                <input
                  type={"radio"}
                  name="certifications"
                  value="single facility"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setType(e.currentTarget.value);
                    }
                  }}
                />
                Owner or scheduler of a facility
              </label>
              <label
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  fontSize: "inherit",
                  fontWeight: 400,
                  gap: 8,
                }}
              >
                <input
                  type={"radio"}
                  name="certifications"
                  value="multiple facilities"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setType(e.currentTarget.value);
                    }
                  }}
                />
                Owner or scheduler of a company with multiple facilities
              </label>
            </fieldset>
          </label>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {loading === true ? (
              <Oval
                color={Colors.bluePrimary}
                secondaryColor={Colors.bluePrimaryBright}
                height={64}
                width={20}
                strokeWidth={8}
              />
            ) : (
              <ButtonUnstyled
                type="submit"
                style={{
                  WebkitAppearance: "none",
                  marginTop: 24,
                  borderRadius: 4,
                  padding: 12,
                  fontSize: 20,
                  color: validate() === true ? Colors.bluePrimary : "lightgray",
                  fontWeight: 800,
                  background: Colors.border,
                  border:
                    validate() === true
                      ? `1px solid ${Colors.bluePrimary}`
                      : `1px solid transparent`,
                  cursor: validate() === true ? "pointer" : "not-allowed",
                  fontFamily: "Urbanist",
                  flex: 1,
                  display: "flex",
                  textAlign: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                disabled={!validate()}
                onClick={() => {
                  setLoading(true);
                  Send();
                  // axios.setDialog({
                  //   title: "Success!",
                  //   message: "You've been signed up.",
                  // });
                }}
              >
                Signup
              </ButtonUnstyled>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
