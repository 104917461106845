export default {
  vBlack: "#01161e",
  vGray: "#4C5F6B",
  vDarkBlue: "#4000F0",
  vGreen: "#4FD3C4",
  vOrange: "#F04000",
  vRed: "#E54B4B",
  vWhite: "#EAEAEA",
  bluePrimary: "#443DF6",
  bluePrimaryBright: "#006FFF",
  bluePrimaryDark: "#0000B1",
  border: "#EFEFF4",
};
