import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { FaChevronDown } from "react-icons/fa";
import Colors from "../styles/Colors";

export default function FAQ() {
  const FAQs = [
    {
      title: "For Providers",
      questions: [
        {
          id: 1,
          q: "What happens if a hospital cancels my gig?",
          a: "If a hospital cancels your gig before 1 week before the start of your gig, there is no penalty. If a hospital cancels your gig between 1 week and 72 hours before your gig, you will be guaranteed 4 hours of pay. If a hospital cancels within 72 hours of the start date/time your gig, you will get paid in full.",
        },
        {
          id: 2,
          q: "What happens if I don't show up to a gig that I claim?",
          a: "We would be very sad. Seriously, though, this reflects poorly on you, and on Pagerr, and most importantly, impacts patient care. This being the case, there is a no-show fee of $500.",
        },
        {
          id: 3,
          q: "What do I need to be a Pagerr doc?",
          a: "To be a Pagerr contractor, you will need: a. Proof of credentials at any hospital where you are credentialed., b. Signed 1099 Contractor Agreement (you will sign this during onboarding, c. Malpractice insurance. We would prefer that you have your own policy. But, if that's not the case and you're not willing to get one, either we can provide malpractice insurance for (you for a cost), or your facility may provide it for you.)",
        },
        {
          id: 4,
          q: "I currently have a full time job. Will my current employer know that I am on Pagerr?",
          a: "Nope! Pagerr will keep that confidential. We're not interested in your current employer - we just want to help you get better gigs. That being said, if you claim a job at your current employer through Pagerr, they will be informed that you have claimed that job, and thus know that you are on Pagerr.",
        },
        {
          id: 5,
          q: "How do I get paid?",
          a: "We'll pay you monthly (last day of the month).",
        },
        {
          id: 6,
          q: "What if I have a bad experience at a facility?",
          a: "Let us know! We care strongly that you have a good experience with the facilities that utilize Pagerr. If there's something you're not happy about, reach out and we'll do our best to deal with it.",
        },
        {
          id: 7,
          q: "I'm a new grad. Can I use Pagerr?",
          a: "Absolutely. Pagerr might be your best resource when you're first coming out of training. We offer the flexibility to work whenever you want at a variety of locations so you can determine where you might be the best fit. If you end up loving an institution and want to take a full time gig there, awesome! We just ask that you let us know if you end up getting hired somewhere.",
        },
      ],
    },
    {
      title: "For Medical Facilities",
      questions: [
        {
          id: 1,
          q: "How does it work?",
          a: "Staff your OR with with one click. Post a job on Pagerr and it'll be blasted out to your network of credentialed providers. You'll be notified once a provider claims the job!",
        },
        {
          id: 2,
          q: "What happens if a provider cancels on me?",
          a: "First off, we're going to do everything we can to make sure this doesn't happen. But, if it does, we're going to do our best to find a suitable replacement in short order - that's kind of what we do! Providers can cancel a shift up to 15 days before the start of the shift with no penalty. After that, they'll incur a penalty if they decide to cancel.",
        },
      ],
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: 20,
        minHeight: "100vh",
        zIndex: 1,
        overflow: "hidden",
        flexWrap: "wrap",
        textAlign: "left",
        // background: "#fff",
        paddingBottom: 220,
        gap: 80,
      }}
    >
      <h1
        style={{
          zIndex: 1,
          fontWeight: 500,
          marginTop: 40,
        }}
      >
        Frequently Asked Questions
      </h1>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "inherit",
        }}
      >
        {FAQs.map(function (item, index) {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div>
                <p
                  style={{
                    fontSize: 42,
                    fontWeight: 700,
                    color: Colors.vBlack,
                    margin: 0,
                  }}
                >
                  {item.title}
                </p>
                {item.questions.map(function (q, index) {
                  return (
                    <Accordion
                      key={index}
                      style={{
                        padding: 0,
                        background: "transparent",
                        display: "flex",
                        flexDirection: "column",
                        boxShadow: "none",
                        fontSize: 24,
                        flex: 1,
                        maxWidth: 920,
                        borderBottom:
                          index + 1 < item.questions.length
                            ? "1px solid lightgray"
                            : "none",
                      }}
                      sx={{
                        "&:before": {
                          display: "none",
                        },
                      }}
                      disableGutters={true}
                    >
                      <AccordionSummary
                        expandIcon={
                          <FaChevronDown
                            size={16}
                            style={{
                              padding: 16,
                              background: Colors.bluePrimaryBright,
                              borderRadius: 100,
                            }}
                            color={Colors.vWhite}
                          />
                        }
                        style={{
                          display: "flex",
                          flex: 1,
                          fontWeight: 800,
                          padding: 16,
                          color: Colors.bluePrimary,
                        }}
                        sx={{
                          "&:hover": {
                            color: Colors.bluePrimary,
                          },
                        }}
                      >
                        {q.q}
                      </AccordionSummary>
                      <AccordionDetails
                        style={{
                          display: "flex",
                          flex: 1,
                          padding: 16,
                        }}
                      >
                        {q.a}
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
