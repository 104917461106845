import { useState, useEffect } from "react";
import Colors from "../styles/Colors";

export default function IndexContainer(props: {
  heading?: string;
  title?: string;
  body?: string;
  children?: any;
}) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  });

  const updateWidthAndHeight = () => {
    setWindowWidth(window.innerWidth);
  };

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        paddingBottom: 80,
        textAlign: "left",
        marginInline: windowWidth > 800 ? 120 : 12,
      }}
    >
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          alignItems: "stretch",
        }}
      >
        <h3>{props.heading}</h3>
        <h2 style={{ fontWeight: 900, color: Colors.vBlack }}>{props.title}</h2>
        <p style={{ margin: 8, color: Colors.vBlack }}>{props.body}</p>
      </div>
      <div
        style={{
          marginTop: 16,
          width: "100%",
          display: "flex",
          flex: 1,
          flexDirection: "row",
        }}
      >
        {props.children}
      </div>
    </div>
  );
}

export function SideContainer(props: {
  heading: string;
  title: string;
  body: string;
  children?: any;
}) {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        marginInline: 20,
      }}
    >
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          alignItems: "stretch",
        }}
      >
        <h3 style={{ fontSize: 20 }}>{props.heading}</h3>
        <h2 style={{ fontWeight: 700, color: Colors.vBlack, fontSize: 32 }}>
          {props.title}
        </h2>
        <p style={{ margin: 8, color: Colors.vBlack, fontSize: 18 }}>
          {props.body}
        </p>
      </div>
      <div
        style={{
          marginTop: 16,
          width: "100%",
          display: "flex",
          flex: 1,
          flexDirection: "row",
        }}
      >
        {props.children}
      </div>
    </div>
  );
}
