import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Colors from "../styles/Colors";
import { BlogPosting, Content } from "./BlogContent";
import "../styles/blog.css";

export default function Blog() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  });

  const updateWidthAndHeight = () => {
    setWindowWidth(window.innerWidth);
  };

  const [data] = useState<BlogPosting[]>(Content);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 120,
        paddingBottom: 120,
        background: "#fff",
      }}
    >
      <div style={{ marginTop: 120 }}>
        <p style={{ fontSize: 28 }}>Blog</p>
        <h1
          style={{
            fontSize: 60,
            color: Colors.bluePrimary,
            fontWeight: 800,
          }}
        >
          Pagerr Blog
        </h1>
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: 40 }}>
        <p style={{ fontSize: 32, color: Colors.vGray, fontWeight: 700 }}>
          Featured articles
        </p>
        <div
          style={{
            borderRadius: 20,
            marginInline: "12%",
            padding: 20,
            background: "#eaeaea",
          }}
        >
          <p style={{ fontSize: 28, fontWeight: 600 }}>
            Looks like there aren't any blog posts yet! Check back soon.
          </p>
        </div>
        <div
          style={{
            display: "grid",
            flexDirection: "column",
            gridTemplateColumns: windowWidth > 900 ? "1fr 1fr 1fr" : "1fr",
            gap: 32,
            marginInline: windowWidth > 800 ? "4%" : 12,
          }}
        >
          {data.map(function (item, index) {
            return (
              <BlogCard
                date={item.date}
                title={item.title}
                content={item.content}
                id={item.id}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export function BlogCard(props: {
  id: string;
  date: Date;
  title: string;
  content: string[];
  externalLink?: string;
  image?: string;
}) {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate(`./${props.id}`)}
      className="blogCard"
      style={{
        padding: 12,
        border: `1px solid lightgray`,
        borderRadius: 12,
        cursor: "pointer",
        display: "flex",
        paddingBottom: 80,
        flexDirection: "column",
        gap: 12,
      }}
    >
      <div
        style={{
          background: "lightgray",
          borderRadius: 8,
          display: "flex",
          height: 200,
        }}
      >
        <div>
          <p
            style={{
              background: "#fff",
              padding: 6,
              borderRadius: 4,
              fontSize: 16,
              fontWeight: 700,
              margin: 8,
            }}
          >
            {props.date.toLocaleDateString("en-us", {
              day: "2-digit",
              month: "short",
              year: "numeric",
            })}
          </p>
        </div>
      </div>
      <p
        style={{
          fontSize: 20,
          fontWeight: 700,
          color: Colors.vGray,
          textAlign: "left",
        }}
      >
        {props.title}
      </p>
    </div>
  );
}
