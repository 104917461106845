import { ButtonUnstyled } from "@mui/base";
import {
  FaBriefcaseMedical,
  FaClinicMedical,
  FaStethoscope,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Colors from "../styles/Colors";

export default function SignupPicker() {
  const navigate = useNavigate();
  return (
    <div
      style={{
        minHeight: "100vh",
        background: Colors.border,
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
      }}
    >
      <span
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: 20,
        }}
      >
        <div
          style={{
            background: Colors.vDarkBlue,
            borderRadius: 24,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            height: 48,
            width: 48,
          }}
        >
          <FaStethoscope style={{ color: "#FBFBFD" }} size={20} />
        </div>
        <h2
          style={{
            color: Colors.vDarkBlue,
            fontSize: 40,
            fontWeight: 800,
          }}
        >
          Pagerr
        </h2>
        {/* </ButtonUnstyled> */}
      </span>
      <div style={{ display: "flex", gap: 20, flexWrap: "wrap" }}>
        <ButtonUnstyled
          className="jobCard"
          style={{
            display: "flex",
            padding: 40,
            background: "#fff",
            borderRadius: 8,
            alignItems: "center",
            flexDirection: "column",
            cursor: "pointer",
            gap: 12,
            border: "none",
          }}
          onClick={() => navigate("./provider")}
        >
          <FaBriefcaseMedical color={Colors.bluePrimary} size={64} />
          <p
            style={{
              margin: 0,
              fontSize: 40,
              fontWeight: 700,
              color: Colors.bluePrimary,
            }}
          >
            Provider Signup
          </p>
        </ButtonUnstyled>
        <ButtonUnstyled
          className="jobCard"
          style={{
            display: "flex",
            padding: 40,
            background: "#fff",
            borderRadius: 8,
            alignItems: "center",
            flexDirection: "column",
            cursor: "pointer",
            gap: 12,
            border: "none",
          }}
          onClick={() => navigate("./facility")}
        >
          <FaClinicMedical color={Colors.bluePrimaryBright} size={64} />
          <p
            style={{
              margin: 0,
              fontSize: 40,
              fontWeight: 700,
              color: Colors.bluePrimaryBright,
            }}
          >
            Facility Signup
          </p>
        </ButtonUnstyled>
      </div>
    </div>
  );
}
