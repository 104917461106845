import { ButtonUnstyled } from "@mui/base";
import { useState } from "react";
import { FaCopy } from "react-icons/fa";
import Colors from "../styles/Colors";

export default function ReferralGenerator() {
  const [email, setEmail] = useState<string>("");

  function validateEmail(): string {
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      return "Please provide a valid email address";
    }

    return "";
  }

  return (
    <div
      style={{
        minHeight: "100vh",
        background: Colors.border,
        alignItems: "center",
        // justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        gap: 20,
      }}
    >
      <div>
        <h1 style={{ marginTop: 80, fontWeight: 800 }}>Help us help you.</h1>
        <p style={{ fontSize: 24 }}>
          Join our referral program and earn money when your friends use
          Pagerr*.
        </p>
      </div>
      <label>
        Your email
        <input onChange={(e) => setEmail(e.currentTarget.value)}></input>
      </label>

      {!!validateEmail() ? null : (
        <div
          style={{
            background: "#fff",
            display: "flex",
            flexDirection: "column",
            borderRadius: 12,
            padding: 20,
            boxShadow: "4px 4px 8px rgba(0,0,0,0.2)",
            alignItems: "flex-start",
          }}
        >
          <b>Your referral link:</b>
          <span style={{ display: "flex", flexDirection: "column" }}>
            <p
              style={{
                margin: 12,
                fontSize: 32,
                fontWeight: 700,
                cursor: "text",
                color: Colors.bluePrimary,
              }}
            >
              www.pagerr.app/signup/provider/{email}
            </p>
            {/* <ButtonUnstyled
              className="pressable"
              onClick={() => {
                // navigator.clipboard.
                navigator.clipboard.writeText("hello");
                // navigator.clipboard.writeText(
                //   `www.pagerr.app/signup/provider/${email}`
                // );
                alert("Link copied to your clipboard!");
              }}
              style={{
                background: Colors.bluePrimary,
                border: "none",
                borderRadius: 4,
                paddingBlock: 16,
                cursor: "pointer",
                color: Colors.vWhite,
                fontSize: 16,
                fontWeight: 700,
                flex: 0,
                paddingInline: 20,
                alignSelf: "flex-end",
              }}
            >
              Copy Link
            </ButtonUnstyled> */}
          </span>
          <p>Copy and paste this link to your friends!</p>
        </div>
      )}
    </div>
  );
}
