import { ButtonUnstyled } from "@mui/base";
import "../styles/styles.css";
import IndexContainer from "./IndexContainer";
import Colors from "../styles/Colors";
import { useState, useEffect } from "react";
import hero from "../assets/images/happydocs.png";
import pastJobs from "../assets/images/Screenshots/PastJobs.png";
import {
  FaBell,
  FaDotCircle,
  FaHospitalSymbol,
  FaLayerGroup,
  FaMailBulk,
  FaSearchDollar,
  FaSearchLocation,
  FaUsersCog,
  FaWindowRestore,
} from "react-icons/fa";
import {
  MdChatBubble,
  MdEvent,
  MdMailOutline,
  MdSchedule,
  MdSpaceDashboard,
} from "react-icons/md";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import "../styles/landing.css";

export default function Landing() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  });

  const updateWidthAndHeight = () => {
    setWindowWidth(window.innerWidth);
  };

  const mobileTrigger = 800;

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: 120,
          flex: 1,
          zIndex: 1,
          overflow: "hidden",
          flexWrap: "wrap",
          backgroundImage: `linear-gradient(80deg, rgba(1,22,30,0.45), ${Colors.bluePrimary}), url(${hero})`,
          backgroundSize: "cover",
        }}
      >
        <div
          style={{
            zIndex: 1,
            textAlign: "left",
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
            flexDirection: "row",
            display: "flex",
            flexWrap: "wrap",
            gap: 60,
            padding: 24,
          }}
        >
          <div
            style={{
              zIndex: 1,
              textAlign: "center",
              flex: 1,
            }}
          >
            <h1
              style={{
                zIndex: 1,
                fontWeight: 850,
                fontSize: windowWidth > mobileTrigger ? 88 : 48,
                color: Colors.vWhite,
                textShadow: "4px 4px 4px rgba(0,0,0,0.2)",
              }}
            >
              Seamlessly Connecting Anesthesia Professionals and Medical
              Facilities
            </h1>
            <h3
              style={{
                zIndex: 1,
                fontSize: windowWidth > mobileTrigger ? 40 : 28,
                color: Colors.vWhite,
                textShadow: "4px 4px 4px rgba(0,0,0,0.2)",
                marginTop: 12,
                fontWeight: 500,
              }}
            >
              Pick up anesthesia gigs at facilities near you today!
            </h3>
          </div>
          {/* <ContactForm /> */}
        </div>
        <div
          style={{
            background: Colors.border,
            height: 44,
            borderTopLeftRadius: 44,
            borderTopRightRadius: 44,
            boxShadow: "-8px -8px 48 rgba(0,0,0,0.2)",
            width: "100%",
            zIndex: 2,
            marginTop: 120,
          }}
        />
      </div>

      <div
        style={{
          background: Colors.border,
          paddingTop: windowWidth > mobileTrigger ? 20 : 0,
          paddingBottom: 220,
          zIndex: 1,
          gap: 120,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 80,
          }}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              minWidth: "80%",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <h2 style={{ fontWeight: 900, fontSize: 64, marginTop: 40 }}>
              Pagerr for Providers
            </h2>
            <h3>
              Facilities post anesthesiology gigs, you claim them. Simple as
              that.
            </h3>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingInline: 24,
              maxWidth: 1200,
              // gap: 20,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 40,
                marginBottom: 120,
                flex: 1,
                flexWrap: "wrap",
              }}
            >
              <FeatureCard
                icon={FaDotCircle}
                message="Sign up to find and claim shifts near you in one click. We'll let you know when new shifts are posted."
                title="One-Click Job Claiming"
              />
              <FeatureCard
                icon={MdSpaceDashboard}
                message="Sign up now to see how you can schedule and manage all of your upcoming shifts in one place."
                title="Simple Dashboard"
              />
              <FeatureCard
                icon={FaWindowRestore}
                message="Posting a shift means that every relevant appropriately credentialed and certified provider will see it. Why worry about leaving shifts empty? With Pagerr, you don't have to."
                title="Easy Shift Scheduling"
              />
              {/* <FeatureCard
                icon={MdChatBubble}
                message="message"
                title="Integrated Support"
              /> */}
              <FeatureCard
                icon={FaMailBulk}
                message="Always know the status of your shifts. Get real-time updates when jobs are posted, changed, or cancelled."
                title="Push Notifications"
              />
              <FeatureCard
                icon={FaSearchLocation}
                message="Want to pick up more shifts? We'll let you know about nearby facilities that fit your workstyle, so you can begin claiming shifts."
                title="Credentialing Recommendations"
              />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: windowWidth > mobileTrigger ? "row" : "column",
                alignItems: "center",
                flexWrap: "wrap",
                flex: 1,
                gap: 20,
              }}
            >
              <div
                className="shadow"
                style={{
                  borderRadius: 32,
                  background: Colors.bluePrimaryDark,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flex: 1,
                  aspectRatio: "1 / 1",
                  contain: "content",
                }}
              >
                <svg
                  viewBox="0 0 200 200"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ position: "absolute", zIndex: -1 }}
                >
                  <path
                    fill="#00B0F0"
                    d="M52.4,-51.6C60.1,-44.7,53.3,-22.3,48.2,-5.1C43.1,12.1,39.7,24.2,32,38.4C24.2,52.7,12.1,69,-4.7,73.6C-21.4,78.3,-42.9,71.3,-48.3,57.1C-53.7,42.9,-43.1,21.4,-43.1,0C-43.1,-21.5,-53.8,-43,-48.4,-49.9C-43,-56.9,-21.5,-49.3,0.4,-49.7C22.3,-50.1,44.7,-58.6,52.4,-51.6Z"
                    transform="translate(100 100)"
                  />
                </svg>
                <div
                  style={{
                    background: "#fff",
                    boxShadow: "4px 4px 8px rgba(0,0,0,0.4)",
                    borderRadius: 4,
                    paddingInline: 8,
                    paddingBlock: 20,
                    alignItems: "center",
                    flexDirection: "row",
                    display: "flex",
                    flex: 1,
                    maxWidth: 500,
                    minWidth: 190,
                    margin: 20,
                    textAlign: "left",
                  }}
                >
                  <div style={{ color: Colors.vOrange }}>
                    <MdMailOutline size={50} style={{ padding: 12 }} />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                      paddingRight: 8,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                      }}
                    >
                      <p style={{ margin: 0, fontWeight: 800, fontSize: 20 }}>
                        Gmail
                      </p>
                      <p
                        style={{
                          color: Colors.vGray,
                          margin: 0,
                          fontWeight: 400,
                          fontSize: 14,
                          paddingLeft: 4,
                          marginBottom: 2,
                        }}
                      >
                        now
                      </p>
                    </div>
                    <p style={{ margin: 0, fontWeight: 600, fontSize: 20 }}>
                      PAGERR JOB ALERT
                    </p>
                    <p
                      style={{
                        margin: 0,
                        fontWeight: 500,
                        fontSize: 20,
                        maxLines: 2,
                        flexShrink: 0,
                        display: "flex",
                      }}
                    >
                      Thorek Memorial Hospital posted a gig on Pagerr; $310/hr
                      for 8 hours.
                    </p>
                  </div>
                </div>
              </div>
              <div
                style={{
                  borderRadius: 32,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "left",
                  flex: 1,
                }}
              >
                <div
                  style={{
                    paddingInline: 40,
                    paddingTop: 40,
                    paddingBottom: 80,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                  }}
                >
                  {windowWidth > mobileTrigger ? (
                    <div
                      style={{
                        padding: 10,
                        borderRadius: 40,
                        background: Colors.bluePrimary,
                        flex: 0,
                        display: "flex",
                        flexShrink: 0,
                      }}
                    >
                      <FaBell
                        color={Colors.border}
                        size={32}
                        style={{
                          flexShrink: 0,
                        }}
                      />
                    </div>
                  ) : null}
                  <div>
                    <h3
                      style={{
                        fontWeight: 800,
                        fontSize: 32,
                        marginBottom: 20,
                      }}
                    >
                      Find jobs, fast.
                    </h3>
                    <h4>
                      Get notified of jobs at hospitals near you. Set your
                      notification preferences so we know which jobs to tell you
                      about.
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection:
                  windowWidth > mobileTrigger ? "row" : "column-reverse",
                flexWrap: "wrap-reverse",
              }}
            >
              <div
                style={{
                  borderRadius: 32,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "left",
                  flex: 1,
                  minWidth: 200,
                }}
              >
                <div
                  style={{
                    paddingInline: 40,
                    paddingTop: 40,
                    paddingBottom: 80,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                  }}
                >
                  {windowWidth > mobileTrigger ? (
                    <div
                      style={{
                        padding: 10,
                        borderRadius: 40,
                        background: Colors.bluePrimary,
                        flex: 0,
                        display: "flex",
                        flexShrink: 0,
                      }}
                    >
                      <FaSearchDollar
                        color={Colors.border}
                        size={32}
                        style={{
                          flexShrink: 0,
                        }}
                      />
                    </div>
                  ) : null}
                  <div>
                    <h3
                      style={{
                        fontWeight: 800,
                        fontSize: 32,
                        marginBottom: 20,
                      }}
                    >
                      Get credentialed at the right places.
                    </h3>
                    <h4>
                      Pagerr can let you know which hospitals and surgery
                      centers might work well for you for future jobs based on
                      distance, activity, and work quality.
                    </h4>
                  </div>
                </div>
              </div>
              <div
                className="shadow"
                style={{
                  borderRadius: 32,
                  background: Colors.bluePrimary,
                  display: "flex",
                  flex: 1,
                  aspectRatio: "1",
                  justifyContent: "center",
                  contain: "content",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <svg
                  viewBox="0 0 200 200"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ position: "absolute", zIndex: -1 }}
                >
                  <path
                    fill={Colors.bluePrimaryBright}
                    d="M38.7,-55.4C51.2,-44.1,63.2,-34.3,70.7,-20.7C78.3,-7.1,81.4,10.2,78.4,27.4C75.4,44.5,66.3,61.6,52.2,61.6C38.1,61.6,19.1,44.6,-0.3,44.9C-19.6,45.3,-39.1,63,-50.1,62C-61,61,-63.4,41.2,-67.9,23C-72.4,4.9,-79.1,-11.5,-71.3,-19.3C-63.5,-27.2,-41.1,-26.5,-26.8,-37.2C-12.5,-48,-6.3,-70.1,3.4,-74.8C13.1,-79.4,26.1,-66.7,38.7,-55.4Z"
                    transform="translate(100 100)"
                  />
                </svg>

                <div
                  style={{
                    background: "#ffffff",
                    border: "1px solid #eaeaea",
                    borderRadius: 8,
                    margin: 12,
                    flex: 1,
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      margin: 8,
                    }}
                  >
                    <p style={{ fontSize: 14, fontWeight: 700, margin: 0 }}>
                      JOB ALERT
                    </p>
                    <p
                      style={{
                        margin: 0,
                        fontWeight: 700,
                        color: Colors.bluePrimary,
                        fontSize: 14,
                      }}
                    >
                      $1,890 ($210/hr)
                    </p>
                  </div>
                  <div
                    style={{
                      marginBottom: 12,
                      padding: 8,
                      borderRadius: 8,
                      borderTop: "1px solid #eaeaea",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        flex: 1,
                      }}
                    >
                      <p
                        style={{
                          margin: 0,
                          fontWeight: 700,
                          marginRight: 16,
                        }}
                      >
                        Evanston Family Oral Surgery Center
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "space-between",
                        marginTop: 4,
                        flexWrap: "wrap",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          margin: 4,
                          background: "#eaeaea",
                          paddingBlock: 4,
                          paddingInline: 8,
                          borderRadius: 4,
                        }}
                      >
                        <MdEvent
                          size={18}
                          style={{ marginRight: 4, flexShrink: 0 }}
                        />
                        <p style={{ fontSize: 13, fontWeight: 600, margin: 0 }}>
                          {new Date("6 june 2022 8:00:00").toLocaleString(
                            "en-US",
                            {
                              month: "long",
                              day: "numeric",
                              weekday: "short",
                            }
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          margin: 4,
                          background: "#eaeaea",
                          paddingBlock: 4,
                          paddingInline: 8,
                          borderRadius: 4,
                        }}
                      >
                        <MdSchedule
                          size={18}
                          style={{ marginRight: 4, flexShrink: 0 }}
                        />
                        <p style={{ fontSize: 13, fontWeight: 600, margin: 0 }}>
                          {new Date("6 june 2022 8:00:00").toLocaleTimeString(
                            "en-US",
                            {
                              hour: "numeric",
                              minute: "2-digit",
                            }
                          )}
                          {" to "}
                          {new Date("6 june 2022 17:00:00").toLocaleTimeString(
                            "en-US",
                            {
                              hour: "numeric",
                              minute: "2-digit",
                            }
                          )}
                        </p>
                      </div>
                    </div>

                    <div style={{ marginTop: 12, textAlign: "left" }}>
                      <p style={{ margin: 0, fontSize: 13 }}>Specialties</p>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          flexWrap: "wrap",
                        }}
                      >
                        <div
                          style={{
                            background: "#eaeaea",
                            paddingBlock: 4,
                            paddingInline: 12,
                            borderRadius: 12,
                            margin: 2,
                          }}
                        >
                          <p
                            style={{
                              fontWeight: 600,
                              maxLines: 1,
                              margin: 0,
                              fontSize: 14,
                            }}
                          >
                            General Anesthesia
                          </p>
                        </div>
                        <div
                          style={{
                            background: "#eaeaea",
                            paddingBlock: 4,
                            paddingInline: 12,
                            borderRadius: 12,
                            margin: 2,
                          }}
                        >
                          <p
                            style={{
                              fontWeight: 600,
                              maxLines: 1,
                              margin: 0,
                              fontSize: 14,
                            }}
                          >
                            Pediatric (5-18)
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      borderTop: "1px solid #eaeaea",
                      margin: 12,
                      paddingTop: 8,
                      justifyContent: "flex-end",
                      display: "flex",
                    }}
                  >
                    <ButtonUnstyled
                      disabled
                      style={{
                        borderRadius: 4,
                        paddingBlock: 6,
                        paddingInline: 8,
                        background: Colors.border,
                        color: Colors.bluePrimary,
                        fontWeight: 600,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        border: `1px solid ${Colors.bluePrimary}`,
                        fontFamily: "Urbanist",
                      }}
                    >
                      Claim Job
                    </ButtonUnstyled>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: windowWidth > mobileTrigger ? "row" : "column",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <div
                className="shadow"
                style={{
                  borderRadius: 32,
                  background: Colors.bluePrimaryBright,
                  display: "flex",
                  flex: 1,
                  aspectRatio: "1 / 1",
                  justifyContent: "center",
                  contain: "content",
                  flexDirection: "column",
                  gap: 20,
                }}
              >
                <svg
                  viewBox="0 0 200 200"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ position: "absolute", zIndex: -1 }}
                >
                  <path
                    fill={Colors.bluePrimaryDark}
                    d="M34.8,-38.2C46.4,-23.2,58.1,-11.6,63.8,5.7C69.5,23,69.3,46,57.6,57.3C46,68.6,23,68.2,0.4,67.9C-22.3,67.5,-44.6,67.2,-55,55.9C-65.5,44.6,-64,22.3,-63.1,0.9C-62.2,-20.4,-61.7,-40.9,-51.3,-55.9C-40.9,-70.9,-20.4,-80.5,-4.4,-76.1C11.6,-71.7,23.2,-53.2,34.8,-38.2Z"
                    transform="translate(100 100)"
                  />
                </svg>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    color: Colors.bluePrimary,
                    background: Colors.vWhite,
                    padding: 20,
                    borderRadius: 4,
                    marginInline: 20,
                    flexWrap: "wrap",
                    gap: 12,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <FaHospitalSymbol size={40} style={{ flexShrink: 0 }} />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "left",
                      }}
                    >
                      <p
                        style={{
                          fontSize: 16,
                          fontWeight: 600,
                          color: "inherit",
                        }}
                      >
                        Thorek Memorial Hospital
                      </p>
                      <p
                        style={{
                          fontSize: 13,
                          fontWeight: 700,
                          color: Colors.vGray,
                        }}
                      >
                        8 active jobs
                      </p>
                    </div>
                  </div>
                  <p
                    style={{
                      fontSize: 12,
                      fontWeight: 900,
                      color: Colors.vGreen,
                      background: Colors.vWhite,
                      border: `1px solid ${Colors.vGreen}`,
                      borderRadius: 2,
                      paddingBlock: 4,
                      paddingInline: 12,
                      margin: 0,
                      flex: 1,
                    }}
                  >
                    CREDENTIALED
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    color: Colors.bluePrimary,
                    background: Colors.vWhite,
                    padding: 20,
                    borderRadius: 4,
                    marginInline: 20,
                    flexWrap: "wrap",
                    gap: 12,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <FaHospitalSymbol
                      color={Colors.vOrange}
                      size={40}
                      style={{ flexShrink: 0 }}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "left",
                      }}
                    >
                      <p
                        style={{
                          fontSize: 16,
                          fontWeight: 600,
                          color: "inherit",
                        }}
                      >
                        Northwestern Memorial Hospital
                      </p>
                      <p
                        style={{
                          fontSize: 13,
                          fontWeight: 700,
                          color: Colors.vOrange,
                        }}
                      >
                        28 active jobs
                      </p>
                    </div>
                  </div>
                  <p
                    style={{
                      fontSize: 12,
                      fontWeight: 900,
                      color: Colors.vWhite,
                      background: Colors.vOrange,
                      // border: `1px solid ${Colors.vGreen}`,
                      borderRadius: 2,
                      paddingBlock: 4,
                      paddingInline: 12,
                      margin: 0,
                      flex: 1,
                    }}
                  >
                    GET CREDENTIALED
                  </p>
                </div>
              </div>
              <div
                style={{
                  borderRadius: 32,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "left",
                  flex: 1,
                  minWidth: 200,
                }}
              >
                <div
                  style={{
                    paddingInline: 40,
                    paddingTop: 40,
                    paddingBottom: 80,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                  }}
                >
                  {windowWidth > mobileTrigger ? (
                    <div
                      style={{
                        padding: 10,
                        borderRadius: 40,
                        background: Colors.bluePrimary,
                        flex: 0,
                        display: "flex",
                        flexShrink: 0,
                      }}
                    >
                      <FaLayerGroup
                        color={Colors.border}
                        size={32}
                        style={{
                          flexShrink: 0,
                        }}
                      />
                    </div>
                  ) : null}
                  <div>
                    <h3
                      style={{
                        fontWeight: 800,
                        fontSize: 32,
                        marginBottom: 20,
                      }}
                    >
                      Everything in one place
                    </h3>
                    <h4>
                      Keep track of upcoming shifts, past shifts, and
                      credentialing status in one platform - and let Pagerr's
                      team help when something needs to get done.{" "}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            background: Colors.vBlack,
            marginInline: windowWidth > mobileTrigger ? 20 : 0,
            borderRadius: windowWidth > mobileTrigger ? 40 : 0,
            contain: "layout",
          }}
        >
          <IndexContainer>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                paddingBlock: 20,
                color: Colors.vWhite,
                flexWrap: "wrap",
                gap: 60,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                <h2
                  style={{
                    fontWeight: 800,
                    fontSize: 72,
                    color: "inherit",
                    textShadow: "2px 2px 4px rgba(0,0,0,0.4)",
                  }}
                >
                  Pagerr for facilities
                </h2>

                <p
                  style={{
                    flex: 1,
                    fontSize: 28,
                    fontWeight: 400,
                    color: "inherit",
                    textShadow: "2px 2px 4px rgba(0,0,0,0.4)",
                    opacity: 0.8,
                  }}
                >
                  Staff better, whenever.
                </p>
                <ButtonUnstyled
                  className="pressable"
                  style={{
                    display: "flex",
                    marginTop: 20,
                    paddingInline: 64,
                    paddingBlock: 12,
                    fontSize: 32,
                    fontWeight: 700,
                    background: Colors.bluePrimary,
                    border: `1px solid ${Colors.border}`,
                    borderRadius: 4,
                    cursor: "pointer",
                    color: Colors.border,
                  }}
                  onClick={() => window.open("/signup/facility")}
                >
                  Sign your facility up today!
                </ButtonUnstyled>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 40,
                  // marginBlock: 80,
                  flex: 1,
                  flexWrap: "wrap",
                }}
              >
                <img
                  alt="pastjobs"
                  src={pastJobs}
                  style={{
                    display: "flex",
                    borderRadius: 10,
                    boxShadow: `10px 20px 40px 0px rgba(68,61,246,0.3), -20px 0px 80px 0px rgba(234,234,234,0.3), 0px 20px 80px 0px rgba(229,75,75,0.3)`,
                  }}
                  width="100%"
                />
                <FeatureCard
                  icon={MdSpaceDashboard}
                  message="Sign up now to see how you can schedule and manage all of your upcoming anesthesia shifts in one place."
                  title="Simple Dashboard"
                  theme="dark"
                />
                <FeatureCard
                  icon={FaWindowRestore}
                  message="Posting a shift means that every relevant appropriately credentialed and certified provider will see it. Why worry about leaving shifts empty? With Pagerr, you don't have to."
                  title="Easy Shift Scheduling"
                  theme="dark"
                />
                <FeatureCard
                  icon={MdChatBubble}
                  message="Questions? Concerns? Stay in touch with the Pagerr team so we can handle anything that might comes up."
                  title="Integrated Support"
                  theme="dark"
                />
                <FeatureCard
                  icon={FaMailBulk}
                  message="Always know the status of your shifts. Get real-time updates when your posted shifts are picked or dropped."
                  title="Shift Updates"
                  theme="dark"
                />
                <FeatureCard
                  icon={FaDotCircle}
                  message="We'll bill you once a month, no hassles. "
                  title="Seamless Billing"
                  theme="dark"
                />
                <FeatureCard
                  icon={FaUsersCog}
                  message="We'll make sure you're appropriately staffed by recommending additional providers, and helping them through your credentialing process."
                  title="Credentialing Support"
                  theme="dark"
                />
              </div>
            </div>
          </IndexContainer>
        </div>
      </div>
    </div>
  );
}

export function FacilityExample() {
  return (
    <div
      style={{
        contain: "layout",
        display: "flex",
        flex: 1.5,
        justifyContent: "flex-start",
      }}
    >
      <svg
        viewBox="0 0 250 250"
        xmlns="http://www.w3.org/2000/svg"
        style={{ position: "absolute", zIndex: -1 }}
      >
        <path
          fill={Colors.bluePrimary}
          d="M58,-52.4C73.4,-42.6,82.9,-21.3,82.5,-0.3C82.2,20.7,72.1,41.3,56.7,50.4C41.3,59.5,20.7,57,0.8,56.2C-19,55.4,-38,56.2,-52,47.1C-65.9,38,-74.8,19,-72.4,2.4C-70.1,-14.3,-56.4,-28.5,-42.5,-38.3C-28.5,-48.2,-14.3,-53.6,3.5,-57.1C21.3,-60.6,42.6,-62.2,58,-52.4Z"
          transform="translate(100 100)"
        />
      </svg>
      <div
        style={{
          background: "#ffffff",
          border: "1px solid #eaeaea",
          borderRadius: 8,
          boxShadow: "2px 2px 4px rgba(0,0,0,0.2)",
          margin: 12,
          flex: 1,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            flex: 1,
            margin: 8,
            minWidth: 240,
          }}
        >
          <p style={{ fontSize: 14, fontWeight: 700, margin: 0 }}>
            YOUR JOB LISTINGS
          </p>
        </div>
        <Accordion
          style={{
            padding: 0,
            display: "flex",
            flexDirection: "column",
            boxShadow: "none",
            flex: 1,
            border: "1px solid #eaeaea",
            borderRadius: 8,
            margin: 8,
          }}
          sx={{
            "&:before": {
              display: "none",
            },
          }}
          disableGutters={true}
        >
          <AccordionSummary
            style={{
              display: "flex",
              flex: 1,
              background: "transparent",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                flex: 1,
              }}
            >
              <p style={{ margin: 0, fontWeight: 700, marginRight: 16 }}>
                Northwestern Memorial Hospital (ICU)
              </p>
              <p
                style={{
                  margin: 0,
                  fontWeight: 700,
                  color: Colors.bluePrimary,
                  fontSize: 14,
                }}
              >
                $2,850 ($285/hr)
              </p>
              <div
                style={{
                  paddingBlock: 2,
                  paddingInline: 12,
                  background: "#fff",
                  border: `1px solid ${Colors.bluePrimary}`,
                  color: Colors.bluePrimary,
                  borderRadius: 20,
                  fontSize: 12,
                  fontWeight: 700,
                  width: 80,
                  textAlign: "center",
                }}
              >
                FILLED
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails
            style={{
              display: "flex",
              flex: 1,
              background: "transparent",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "space-between",
                marginTop: 4,
                flexWrap: "wrap",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  margin: 4,
                  background: "#eaeaea",
                  paddingBlock: 4,
                  paddingInline: 8,
                  borderRadius: 4,
                }}
              >
                <MdEvent size={18} style={{ marginRight: 4, flexShrink: 0 }} />
                <p style={{ fontSize: 13, fontWeight: 600, margin: 0 }}>
                  {new Date("28 june 2022 14:00:00").toLocaleString("en-US", {
                    month: "long",
                    day: "numeric",
                    weekday: "short",
                  })}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  margin: 4,
                  background: "#eaeaea",
                  paddingBlock: 4,
                  paddingInline: 8,
                  borderRadius: 4,
                }}
              >
                <MdSchedule
                  size={18}
                  style={{ marginRight: 4, flexShrink: 0 }}
                />
                <p style={{ fontSize: 13, fontWeight: 600, margin: 0 }}>
                  {new Date("6 june 2022 14:00:00").toLocaleTimeString(
                    "en-US",
                    {
                      hour: "numeric",
                      minute: "2-digit",
                    }
                  )}
                  {" to "}
                  {new Date("7 june 2022 0:00:00").toLocaleTimeString("en-US", {
                    hour: "numeric",
                    minute: "2-digit",
                  })}
                </p>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          style={{
            padding: 0,
            display: "flex",
            flexDirection: "column",
            boxShadow: "none",
            flex: 1,
            border: "1px solid #eaeaea",
            borderRadius: 8,
            margin: 8,
          }}
          sx={{
            "&:before": {
              display: "none",
            },
          }}
          disableGutters={true}
        >
          <AccordionSummary
            style={{
              display: "flex",
              flex: 1,
              background: "transparent",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                flex: 1,
              }}
            >
              <p style={{ margin: 0, fontWeight: 700, marginRight: 16 }}>
                Northwestern Memorial Hospital
              </p>
              <p
                style={{
                  margin: 0,
                  fontWeight: 700,
                  color: Colors.bluePrimary,
                  fontSize: 14,
                }}
              >
                $2,850 ($285/hr)
              </p>
              <div
                style={{
                  paddingBlock: 2,
                  paddingInline: 12,
                  background: Colors.bluePrimary,
                  border: `1px solid ${Colors.border}`,
                  color: Colors.border,
                  width: 80,
                  textAlign: "center",
                  borderRadius: 20,
                  fontSize: 12,
                  fontWeight: 700,
                }}
              >
                NOT FILLED
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails
            style={{
              display: "flex",
              flex: 1,
              background: "transparent",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "space-between",
                marginTop: 4,
                flexWrap: "wrap",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  margin: 4,
                  background: "#eaeaea",
                  paddingBlock: 4,
                  paddingInline: 8,
                  borderRadius: 4,
                }}
              >
                <MdEvent size={18} style={{ marginRight: 4, flexShrink: 0 }} />
                <p style={{ fontSize: 13, fontWeight: 600, margin: 0 }}>
                  {new Date("28 june 2022 14:00:00").toLocaleString("en-US", {
                    month: "long",
                    day: "numeric",
                    weekday: "short",
                  })}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  margin: 4,
                  background: "#eaeaea",
                  paddingBlock: 4,
                  paddingInline: 8,
                  borderRadius: 4,
                }}
              >
                <MdSchedule
                  size={18}
                  style={{ marginRight: 4, flexShrink: 0 }}
                />
                <p style={{ fontSize: 13, fontWeight: 600, margin: 0 }}>
                  {new Date("6 june 2022 14:00:00").toLocaleTimeString(
                    "en-US",
                    {
                      hour: "numeric",
                      minute: "2-digit",
                    }
                  )}
                  {" to "}
                  {new Date("7 june 2022 0:00:00").toLocaleTimeString("en-US", {
                    hour: "numeric",
                    minute: "2-digit",
                  })}
                </p>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          style={{
            padding: 0,
            display: "flex",
            flexDirection: "column",
            boxShadow: "none",
            flex: 1,
            border: "1px solid #eaeaea",
            borderRadius: 8,
            margin: 8,
          }}
          sx={{
            "&:before": {
              display: "none",
            },
          }}
          disableGutters={true}
        >
          <AccordionSummary
            style={{
              display: "flex",
              flex: 1,
              background: "transparent",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                flex: 1,
              }}
            >
              <p style={{ margin: 0, fontWeight: 700, marginRight: 16 }}>
                Northwestern Memorial Hospital
              </p>
              <p
                style={{
                  margin: 0,
                  fontWeight: 700,
                  color: Colors.bluePrimary,
                  fontSize: 14,
                }}
              >
                $2,850 ($285/hr)
              </p>
              <div
                style={{
                  paddingBlock: 2,
                  paddingInline: 12,
                  background: Colors.border,
                  border: `1px solid ${Colors.bluePrimary}`,
                  color: Colors.bluePrimary,
                  borderRadius: 20,
                  fontSize: 12,
                  fontWeight: 700,
                  width: 80,
                  textAlign: "center",
                }}
              >
                FILLED
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails
            style={{
              display: "flex",
              flex: 1,
              background: "transparent",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "space-between",
                marginTop: 4,
                flexWrap: "wrap",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  margin: 4,
                  background: "#eaeaea",
                  paddingBlock: 4,
                  paddingInline: 8,
                  borderRadius: 4,
                }}
              >
                <MdEvent size={18} style={{ marginRight: 4, flexShrink: 0 }} />
                <p style={{ fontSize: 13, fontWeight: 600, margin: 0 }}>
                  {new Date("28 june 2022 14:00:00").toLocaleString("en-US", {
                    month: "long",
                    day: "numeric",
                    weekday: "short",
                  })}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  margin: 4,
                  background: "#eaeaea",
                  paddingBlock: 4,
                  paddingInline: 8,
                  borderRadius: 4,
                }}
              >
                <MdSchedule
                  size={18}
                  style={{ marginRight: 4, flexShrink: 0 }}
                />
                <p style={{ fontSize: 13, fontWeight: 600, margin: 0 }}>
                  {new Date("6 june 2022 14:00:00").toLocaleTimeString(
                    "en-US",
                    {
                      hour: "numeric",
                      minute: "2-digit",
                    }
                  )}
                  {" to "}
                  {new Date("7 june 2022 0:00:00").toLocaleTimeString("en-US", {
                    hour: "numeric",
                    minute: "2-digit",
                  })}
                </p>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}

export function FeatureCard(props: {
  title: string;
  message: string;
  icon: any;
  theme?: "light" | "dark";
}) {
  return (
    <div
      className={props.theme === "dark" ? "jobCardDark" : "jobCardLight"}
      style={{
        display: "flex",
        gap: 12,
        flexDirection: "column",
        flex: 1,
        padding: 32,
        borderRadius: 20,
        minWidth: 200,
      }}
    >
      <props.icon
        color={Colors.bluePrimaryBright}
        size={48}
        style={{ marginBottom: 12 }}
      />
      <p style={{ color: "inherit", fontSize: 32, fontWeight: 800, margin: 0 }}>
        {props.title}
      </p>
      <p
        style={{
          color: "inherit",
          fontSize: 20,
          fontWeight: 500,
          opacity: 0.8,
          margin: 0,
        }}
      >
        {props.message}
      </p>
    </div>
  );
}
