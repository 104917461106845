import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import Landing from "./components/Landing";
import ScrollToTop from "./helpers/ScrollToTop";
import FAQ from "./components/Faq";
import FacilitySignup from "./components/FacilitySignup";
import ProviderSignup from "./components/ProviderSignup";
import SignupPicker from "./components/SignupPicker";
import ReferralGenerator from "./components/ReferralGenerator";
import Blog from "./components/Blog";
import BlogPost from "./components/BlogPost";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route path="/">
            <Route element={<App />}>
              <Route index element={<Landing />} />
              <Route path="providers" element={<Outlet />}>
                <Route path="referral" element={<ReferralGenerator />} />
              </Route>
              <Route path="faq" element={<FAQ />} />
              <Route path="*" element={<Landing />} />
              <Route path="blog" element={<Outlet />}>
                <Route index element={<Blog />} />
                <Route path=":postId" element={<BlogPost />} />
              </Route>
            </Route>
            <Route path="signup" element={<Outlet />}>
              <Route index element={<SignupPicker />} />
              <Route path="facility" element={<FacilitySignup />} />
              <Route path="provider" element={<ProviderSignup />}>
                <Route path=":ref" element={<ProviderSignup />} />
              </Route>
            </Route>
          </Route>
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
