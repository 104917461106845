import { ButtonUnstyled } from "@mui/base";
import { FaChevronLeft } from "react-icons/fa";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import Colors from "../styles/Colors";
import { BlogPosting, Content } from "./BlogContent";

export default function BlogPost() {
  const param = useParams();
  const id = param.postId;

  const navigate = useNavigate();

  const blog: BlogPosting[] = Content;

  const post = blog.find((p: BlogPosting) => p.id === id);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 120,
        paddingBottom: 120,
      }}
    >
      <div
        style={{
          background: Colors.bluePrimary,
          paddingTop: 80,
          paddingBottom: 120,
          paddingInline: "12%",
          textAlign: "left",
        }}
      >
        <div style={{ marginBottom: 80 }}>
          <ButtonUnstyled
            onClick={() => navigate("/blog")}
            style={{
              border: "none",
              background: "none",
              fontSize: 20,
              color: Colors.vWhite,
              gap: 12,
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              fontWeight: 700,
            }}
          >
            <FaChevronLeft size={16} />
            Back to Blog
          </ButtonUnstyled>
        </div>
        <p
          style={{
            fontSize: 20,
            color: Colors.border,
            fontWeight: 400,
            margin: 0,
          }}
        >
          {post?.date!.toLocaleDateString("en-us", {
            month: "long",
            day: "numeric",
            year: "numeric",
          })}
        </p>
        <h1
          style={{
            fontSize: 64,
            color: Colors.border,
            fontWeight: 700,
          }}
        >
          {post?.title}
        </h1>
        <p
          style={{
            fontSize: 32,
            color: Colors.border,
            fontWeight: 400,
            margin: 8,
          }}
        >
          {post?.subtitle ?? ""}
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          textAlign: "left",
          gap: 40,
          marginInline: "12%",
          marginBottom: 120,
        }}
      >
        <p
          style={{
            fontSize: 32,
            fontWeight: 700,
            color: Colors.vGray,
            textAlign: "left",
          }}
        >
          {post!.title}
        </p>
        {post?.content.map(function (p, index) {
          return <p style={{ fontSize: 24 }}>{p}</p>;
        })}
      </div>
    </div>
  );
}
